import React from 'react'

import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import ChevronDown from '@interco/icons/orangeds/MD/chevron-down'
import Download from '@interco/icons/orangeds/XL/download'
import Loading from '@interco/icons/orangeds/XL/loading'
import NoTaxes from '@interco/icons/orangeds/MD/no-taxes'
import MoneyCheck from '@interco/icons/orangeds/MD/money-check'
import TransferMoney from '@interco/icons/orangeds/LG/transfer-money'
import Filter from '@interco/icons/orangeds/MD/filter'
import Search from '@interco/icons/orangeds/MD/search'
import CheckCircle from '@interco/icons/orangeds/MD/check-circle'
import Entry from '@interco/icons/orangeds/MD/money-entry'
import Canceled from '@interco/icons/orangeds/MD/canceled'
import Attention from '@interco/icons/orangeds/MD/attention'
import Copy from '@interco/icons/orangeds/MD/copy'
import ArrowLeft from '@interco/icons/orangeds/MD/arrow-left'

export interface IconProps {
  color?: string
  name: string
  width?: number
  height?: number
  onClick?: () => void
}

export const Icons: Record<string, (props: IconProps) => JSX.Element | null> = {
  chevronright: (props: IconProps) => <ChevronRight data-testid="chevronright" {...props} />,
  chevrondown: (props: IconProps) => <ChevronDown data-testid="chevrondown" {...props} />,
  download: (props: IconProps) => <Download data-testid="download" {...props} />,
  notaxes: (props: IconProps) => <NoTaxes data-testid="notaxes" {...props} />,
  moneycheck: (props: IconProps) => <MoneyCheck data-testid="moneycheck" {...props} />,
  transfermoney: (props: IconProps) => <TransferMoney data-testid="transfermoney" {...props} />,
  filter: (props: IconProps) => <Filter data-testid="filter" {...props} />,
  search: (props: IconProps) => <Search data-testid="search" {...props} />,
  checkcircle: (props: IconProps) => <CheckCircle data-testid="checkcircle" {...props} />,
  entry: (props: IconProps) => <Entry data-testid="entry" {...props} />,
  canceled: (props: IconProps) => <Canceled data-testid="canceled" {...props} />,
  attention: (props: IconProps) => <Attention data-testid="attention" {...props} />,
  copy: (props: IconProps) => <Copy data-testid="copy" {...props} />,
  arrowleft: (props: IconProps) => <ArrowLeft data-testid="arrowLeft" {...props} />,
  loading: (props: IconProps) => <Loading data-testid="loading" {...props} />,
}
export const Icon = ({ name, width, height, onClick, color = 'var(--primary500)' }: IconProps) => {
  const IconComponent = Icons[name.toLowerCase().replace(/[-]/g, '')]
  if (IconComponent) return IconComponent({ name, color, width, height, onClick })
  return <span data-testid="no-icon" />
}
