import React from 'react'

import { Timeline } from '@interco/inter-ui/components/Timeline'

import { IconBackground } from './styles'
import { Icon } from '../icon'

const returnStatus = (color: string) => {
  switch (color) {
    case '--success500':
      return 'Success'
    case '--alert500':
      return 'Alert'
    case '--error500':
      return 'Error'
    default:
      return 'Disabled'
  }
}
export const TimeLine = ({ timeline }: { timeline: TimelineItem[] }) => (
  <Timeline>
    {timeline.map((data) => (
      <Timeline.Item
        title={data.title}
        icon={
          data.icon.id !== '' ? (
            <IconBackground>
              <Icon name={data.icon.id} height={20} width={20} color={`var(${data.color})`} />
            </IconBackground>
          ) : (
            false
          )
        }
        type={returnStatus(data.color)}
      >
        <>
          {data.description}
          <br />
        </>
        {data.date}
      </Timeline.Item>
    ))}
  </Timeline>
)
