import React from 'react'

import { useNavigate } from 'react-router-dom'

import { BaseContainer } from '@/components/base-container'
import { Signal } from '@interco/inter-ui/components/Signal'
import { Icon } from '@/components/icon'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { RoutesNames } from '@/routes/routes'

export const ErrorPage = () => {
  const navigate = useNavigate()

  return (
    <BaseContainer headerTitle="" animationType="fade">
      <div className="flex flex-col items-center justify-between h-full w-full">
        <div />
        <div className="flex flex-col items-center gap-[12px] ">
          <Signal
            icon={() => (
              <Icon name="canceled" color="var(--neutral-theme)" width={32} height={32} />
            )}
            color="var(--error500)"
            iconColor="white"
          />
          <Text color="typography" colorWeight={600} semiBold variant="headline-h1">
            Houve um erro por aqui
          </Text>
          <Text color="typography" semiBold variant="body-3">
            Você pode tentar novamente mais tarde.
          </Text>
        </div>
        <div className="flex flex-col items-center gap-[12px] w-full">
          <Button fullWidth onClick={() => navigate(RoutesNames.HOME, { replace: true })}>
            Tentar novamente
          </Button>
          <Button fullWidth variant="secondary">
            Voltar
          </Button>
        </div>
      </div>
    </BaseContainer>
  )
}
