import React, { PropsWithChildren } from 'react'

import * as S from './styles'

export interface IIconButtonProps {
  onClick?: () => void
  hoverColor?: string
}
export const IconButton = ({
  hoverColor,
  onClick,
  children,
}: PropsWithChildren<IIconButtonProps>) => {
  const stopPropagation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fn: () => void,
  ) => {
    event.stopPropagation()
    fn()
  }
  return (
    <S.IconButton
      data-testid="btn-copy"
      hoverColor={hoverColor}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        onClick && stopPropagation(e, onClick)
      }
    >
      {children}
    </S.IconButton>
  )
}
