import React, { PropsWithChildren } from 'react'

import { AppBar } from '@interco/inter-ui/components/AppBar'

import {
  Container,
  ContainerWithFade,
  ContainerBottonTop,
  ContainerRightLeft,
  ContainerLeftRight,
} from './styles'
import { Icon } from '../icon'

interface IBaseContainer {
  headerTitle?: string
  onClickArrowLeft?: () => void
  animationType: 'fade' | 'left-right' | 'right-left'
}
export const BaseContainer = ({
  headerTitle,
  children,
  onClickArrowLeft,
  animationType,
}: PropsWithChildren<IBaseContainer>) => (
  <Container>
    {headerTitle && (
      <AppBar
        leftIcon={
          <button
            type="button"
            id="arrowLeft"
            style={{ backgroundColor: 'transparent' }}
            onClick={onClickArrowLeft}
          >
            <Icon name="arrowLeft" width={24} height={24} />
          </button>
        }
      >
        {headerTitle}
      </AppBar>
    )}
    {animationType === 'left-right' && <ContainerLeftRight>{children}</ContainerLeftRight>}
    {animationType === 'right-left' && <ContainerRightLeft>{children}</ContainerRightLeft>}
    {animationType === 'fade' && <ContainerWithFade>{children}</ContainerWithFade>}
  </Container>
)
export const BaseContainerRoot = ({ children }: { children: React.ReactNode }) => (
  <ContainerBottonTop>{children}</ContainerBottonTop>
)
