import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const a = 'a'

export const getHomePage = async (cpfCnpj: string) => {
  const response = await api<HomePage>(`${ENDPOINTS.HOME_PAGE}`, {
    method: 'GET',
    headers: { 'x-inter-cpf-cnpj': cpfCnpj },
  })
  if (!response) {
    return undefined
  }
  return response
}
