import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'

export const DetailLabelValueSection = ({ data }: { data: Section }) => (
  <div className="flex flex-col gap-[16px]">
    <Text variant="headline-h1" semiBold>
      {data.title}
    </Text>
    {data.items.map(({ title, value }) => (
      <div className="flex flex-row justify-between gap-[4px]">
        <Text variant="caption-1">{title}</Text>
        <Text variant="caption-1" bold colorWeight={500} className="break-keep text-right">
          {value}
        </Text>
      </div>
    ))}
  </div>
)
