import React from 'react'

import { ChipContainer, ChipText } from './styles'

export interface ChipStatusProps {
  label: string
  textColor: string
  color: string
}

export const ChipStatus = ({ color, textColor, label }: ChipStatusProps) => (
  <ChipContainer color={color}>
    <ChipText textColor={textColor} colorWeight={500}>
      {label}
    </ChipText>
  </ChipContainer>
)
