export const ACCOUNT_KEY = '@inter-cdpro/account-data'

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
export const IS_TEST = process.env.NODE_ENV === 'test'

export enum ACTION_TYPE {
  labelWithIcon = 'label-with-icon-and-action',
  button = 'button',
}

export enum ACTION_ID {
  copy = 'COPY_NUMERO_PROCESSO',
  detail = 'BUTTON_DETAILS',
  download = 'BUTTON_DOWNLOAD',
}
