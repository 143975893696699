import InterWebviewBridge, {
  interWbSession,
  interWbHttp,
  interWbNavigate,
  IWbUserInfo,
  IWbHttpOptions,
  IWbHttpData,
  WbEnvironments,
  IWbAppInfo,
} from '@interco/inter-webview-bridge'

import USER_INFO_MOCK from '../../mocks/responses/user-info'
import { DeepLinksPF, DeepLinksPJ, DeepLinksKeys, DeepLinks } from './deeplinks'
import * as Types from './types'

export { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'
export { DeepLinks, DeepLinksPJ, DeepLinksPF }

export const iWb = InterWebviewBridge.getInstance()
export const inApp = iWb.getEnvironment() !== WbEnvironments.BROWSER

export const getAppInfo = (): Promise<IWbAppInfo> =>
  inApp
    ? interWbSession.getAppInfo()
    : Promise.resolve({
        version: 'version',
        theme: 'inter',
        tema: 'inter',
        deviceName: 'deviceName',
        deviceType: 'SMARTPHONE',
        nomeAparelho: 'nomeAparelho',
        versao: 'versao',
        nomeTipo: 'nomeAparelho',
        dimensions: { height: 1647, width: 1080 },
        appVersion: '',
        isDarkMode: false,
        application: 'PF',
        organization: 'PF',
        language: 'pt-BR',
        env: 'hmg',
      })
export const getUserInfo = (): Promise<IWbUserInfo> =>
  inApp ? interWbSession.getUserInfo() : Promise.resolve(USER_INFO_MOCK)

export const shareContent = (text: string): Promise<unknown> =>
  inApp ? interWbSession.shareContent(text) : Promise.resolve()

export const requestAnalytics = (name: string, params?: Record<string, string>) => {
  if (!inApp) return
  interWbSession.requestAnalytics(name, params)
}

export async function request<T = Record<string, unknown>>({
  method,
  endpoint,
  ...args
}: Types.Request): Promise<RequestResponse<T>> {
  let response: IWbHttpData
  const headers = args.headers || {}
  const options: IWbHttpOptions = { timeout: 30_000, ...args.options }
  try {
    switch (method) {
      default:
      case 'GET':
      case 'DELETE':
        response = await interWbHttp[method.toLowerCase() as 'get' | 'delete'](
          endpoint,
          headers,
          options,
        )
        break

      case 'POST':
      case 'PUT':
        response = await interWbHttp[method.toLowerCase() as 'post' | 'put'](
          endpoint,
          (args as Types.PostOrPut).body,
          headers,
          options,
        )
        break
    }

    const data = response

    return { headers: data.headers, response: JSON.parse(data.response || '[]') }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getSuperAppType = async (): Promise<Types.SuperAppType | undefined> => {
  if (inApp) {
    return iWb.execute<Types.SuperAppType, undefined>({
      action: Types.Actions.GET_SUPER_APP_TYPE,
    })
  }

  return Promise.resolve(undefined)
}

export const navigateToNativeScreen = async (screen: DeepLinksKeys): Promise<unknown> => {
  if (!inApp) {
    return Promise.resolve(undefined)
  }

  const type = await getSuperAppType()

  if (type?.superAppType === ('PF' as AppType)) {
    return interWbNavigate.openDeepLink(DeepLinksPF[screen])
  }

  if (DeepLinksPJ[screen as keyof typeof DeepLinksPJ]) {
    return interWbNavigate.openDeepLink(DeepLinksPJ[screen as keyof typeof DeepLinksPJ])
  }

  return Promise.resolve(undefined)
}

export const navigateDeepLink = async (url: string): Promise<unknown> => {
  if (!inApp) {
    return Promise.resolve(undefined)
  }

  return interWbNavigate.openDeepLink(url)
}

export const shareWebState = (): Promise<void> => {
  if (inApp) {
    return iWb.execute<void, undefined>({
      action: Types.Actions.SHARE_WEB_VIEW_SCREENSHOT,
    })
  }

  return Promise.resolve(undefined)
}

export const getSessionType = async (): Promise<Types.SessionType> => {
  if (inApp) {
    return iWb.execute<Types.SessionType, unknown>({
      action: Types.Actions.GET_SESSION_TYPE,
    })
  }

  return Promise.resolve({ isBasicAccount: false })
}

export const callPhoneNumber = async (phoneNumber: string): Promise<void> => {
  if (inApp) {
    return iWb.execute<undefined, unknown>({
      action: Types.Actions.CALL_PHONE_NUMBER,
      metadata: { phoneNumber },
    })
  }

  return Promise.resolve(undefined)
}

export const downloadFile = (fileUrl: string): Promise<unknown> => {
  if (inApp) {
    const result = iWb.execute<unknown, unknown>({
      action: Types.Actions.DOWNLOAD_FILE,
      metadata: {
        url: fileUrl,
        name: 'teste',
        fileName: 'teste2.pdf',
      },
    })

    return result
  }

  return Promise.resolve(undefined)
}

export const copyTextToClipboard = (text: string): Promise<unknown> => {
  if (inApp) {
    const result = iWb.execute<unknown, unknown>({
      action: Types.Actions.COPY_TEXT_TO_CLIPBOARD,
      metadata: {
        text,
      },
    })

    return result
  }

  return Promise.resolve(undefined)
}

export const shareFileAndroid = (fileUrl: Record<string, string>): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.SHARE_FILE_ANDROID,
      metadata: {
        fileUrl,
      },
    })
  }

  return Promise.resolve(undefined)
}

export const verifyOnboarding = (): Promise<Types.VerifyOnboarding> => {
  if (inApp) {
    return iWb.execute<Types.VerifyOnboarding, unknown>({
      action: Types.Actions.GET_ONBOARDING,
    })
  }

  return Promise.resolve({ onboardingWasPresented: false })
}

export const setOnboarding = (onboardingWasPresented: boolean): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.SET_ONBOARDING,
      metadata: {
        onboardingWasPresented,
      },
    })
  }

  return Promise.resolve(undefined)
}

export const openNativeBrowser = (url: string): Promise<string | void> => {
  if (inApp) {
    return iWb.execute<string, unknown>({
      action: Types.Actions.OPEN_NATIVE_BROWSER,
      metadata: { url },
    })
  }

  return Promise.resolve(undefined)
}
