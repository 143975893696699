import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { BaseContainer } from '@/components/base-container'
import * as ENDPOINTS from '@/common/constants/endpoints'
import { ApiService, BridgeService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { RoutesNames } from '@/routes/routes'
import { DetailPageSkeleton } from '@/components/page-skeletons'
import { Signal } from '@interco/inter-ui/components/Signal'
import { Icon } from '@/components/icon'
import { Text } from '@interco/inter-ui/components/Text'
import { ChipStatus } from '@/components/chip-status'
import { DetailLabelValueSection } from '@/components/detail-label-value-section'
import { setDetailPage } from '@/contexts/global-state/reducer/actions'
import { inApp } from '@/services/bridge'

export const Detail = () => {
  const [{ homePage, detailPage, user, detailProtocolNumber }, dispatch] = useGlobalState()
  const navigate = useNavigate()

  const [showDowload, setShowDowload] = useState<boolean>(false)
  const { refetch, isFetching } = useQuery({
    queryKey: [ENDPOINTS.DETAIL_PAGE],
    queryFn: async () => {
      try {
        const resp = await ApiService.Detail.getDetailPage(
          user?.cpfCnpj || '',
          detailProtocolNumber || '',
        )

        if (resp === undefined) {
          return null
        }
        const { response } = resp

        dispatch(setDetailPage(response))
        return response
      } catch (e) {
        navigate(RoutesNames.ERROR, { replace: true })
        return null
      }
    },
    /* {
      enabled: false,
      retry: false,
      retryDelay: 1000,

      onSuccess: (resp) => {
        if (resp !== undefined) {
          const { response } = resp
          dispatch(setDetailPage(response))
        }
      },

      onError: () => {
        navigate(RoutesNames.ERROR, { replace: true })
      },} */
  })
  const download = async () => {
    setShowDowload(true)
    if (inApp && detailProtocolNumber) {
      const reps = await ApiService.Download.getCourtBlockPdfUrl({
        cpfCnpj: user?.cpfCnpj || '',
        protocolNumber: detailProtocolNumber,
      })
      const response = reps?.response
      if (response)
        BridgeService.iWb
          .execute<unknown, unknown>({
            action: 'downloadFile',
            metadata: { fileUrl: response.url, fileExtension: 'pdf', fileName: 'Ordem judicial' },
          })
          .finally(() => {
            setShowDowload(false)
          })
    }
  }
  useEffect(() => {
    refetch()
  }, [refetch])
  const goBack = () => {
    navigate(-1)
  }

  const Divider = () => (
    <div className="border-dashed border-[0px] border-b-[1px] border-b-[var(--gray200)] my-[24px]" />
  )
  const DividerFullWidth = () => (
    <div className="border-solid w-[100vw] ml-[-24px] border-[0px] border-b-[8px] border-b-[var(--gray200)] my-[32px]" />
  )
  return (
    <BaseContainer
      animationType="right-left"
      headerTitle={homePage?.header || ''}
      onClickArrowLeft={goBack}
    >
      <div className="flex flex-col items-center gap-[16px] mt-[24px] ">
        <Signal
          icon={() => (
            <Icon name={detailPage?.icon || ''} color="var(--gray500)" width={32} height={32} />
          )}
          color="var(--gray100)"
          iconColor="var(--gray500)"
        />
        <div className="flex flex-col gap-[4px] items-center">
          <Text color="typography" colorWeight={600} semiBold variant="headline-h1">
            {detailPage?.amount}
          </Text>
          <Text color="typography" semiBold variant="body-3" className="capitalize">
            {detailPage?.name?.toLocaleLowerCase()}
          </Text>
        </div>
        <ChipStatus
          label={detailPage?.status.title || ''}
          textColor={detailPage?.status.colorText || 'var(--gray500)'}
          color={detailPage?.status.color || 'ver(--gray100)'}
        />
      </div>
      <Divider />
      {detailPage?.sections.map((section, index) => [
        <DetailLabelValueSection data={section} />,
        index + 1 !== detailPage.sections.length && <Divider />,
      ])}
      {isFetching && <DetailPageSkeleton />}
      <DividerFullWidth />

      <Text color="typography" semiBold variant="headline-h3">
        Ação
      </Text>
      <button
        type="button"
        className="flex  bg-transparent flex-row w-full items-center justify-between h-[56px] mt-[16px]"
        onClick={download}
      >
        <div className="flex flex-row w-full items-center justify-start gap-[16px]">
          <Icon name="download" width={24} height={24} color="var(--gray500)" />
          <Text color="typography" bold variant="body-3" colorWeight={500}>
            Baixar ordem judicial
          </Text>
        </div>
        {showDowload ? (
          <div className="animate-spin h-[24px] w-[24px]">
            <Icon name="loading" width={24} height={24} />
          </div>
        ) : (
          <Icon name="chevronright" width={24} height={24} />
        )}
      </button>
    </BaseContainer>
  )
}
