import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { formatToBRL } from '@interco/lib-util'

import { Icon } from '../icon'
import { ChipStatus } from '../chip-status'

export const HeaderCard = ({ title, icon, courtBlockInfo, color, colorText }: CourtBlockStatus) => (
  <div className="w-full border-[1px]  rounded-[8px] flex justify-between">
    <div className="flex flex-row gap-[16px] items-center">
      <div>
        <Icon name={icon?.id || ''} width={24} height={24} color="var(--gray500)" />
      </div>
      <div className="flex flex-col gap-[8px]">
        <div className="flex flex-col gap-[4px]">
          <Text variant="body-3" bold colorWeight={500}>
            {formatToBRL(Number(courtBlockInfo?.blockedAmount))}
          </Text>
          <Text variant="body-3" className="capitalize">
            {courtBlockInfo?.author?.toLowerCase()}
          </Text>
        </div>
        <ChipStatus label={title} textColor={colorText || 'var(--gray500)'} color={color} />
      </div>
    </div>
  </div>
)
