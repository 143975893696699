export const Home: HomePage = {
  header: 'Bloqueio Judicial',
  blockedValue: {
    title: 'Valor bloqueado',
    value: 'R$ 0,00',
  },
  blocks: [
    {
      status: {
        type: 'UNBLOCKED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Desbloqueado parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'ORDER_ENDED',
          icon: {
            id: 'check-circle',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor disponível em sua conta',
          date: 'Terça, 06/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
    {
      status: {
        type: 'UNBLOCKED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Desbloqueado parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'ORDER_ENDED',
          icon: {
            id: 'check-circle',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor disponível em sua conta',
          date: 'Terça, 06/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
    {
      status: {
        type: 'UNBLOCKED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Desbloqueado parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'ORDER_ENDED',
          icon: {
            id: 'check-circle',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor disponível em sua conta',
          date: 'Terça, 06/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
    {
      status: {
        type: 'UNBLOCKED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Desbloqueado parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'ORDER_ENDED',
          icon: {
            id: 'check-circle',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor disponível em sua conta',
          date: 'Terça, 06/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
    {
      status: {
        type: 'UNBLOCKED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Desbloqueado parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'ORDER_ENDED',
          icon: {
            id: 'check-circle',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor disponível em sua conta',
          date: 'Terça, 06/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
    {
      status: {
        type: 'UNBLOCKED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Desbloqueado parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'ORDER_ENDED',
          icon: {
            id: 'check-circle',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor disponível em sua conta',
          date: 'Terça, 06/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
    {
      status: {
        type: 'TRANSFERED_PARTIAL',
        icon: {
          id: 'no-taxes',
        },
        color: 'black',
        title: 'Transferido parcialmente',
        courtBlockInfo: {
          blockedAmount: '50.00',
          author: 'JESSICA CAROLINN DE LIMA OLIVEIRA',
        },
      },
      timeline: [
        {
          status: 'ISSUED_ORDER',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem emitida',
          description: 'Protocolo: 20210000811326.',
          date: 'Segunda, 05/04/2021',
        },
        {
          status: 'PROCESS_RESULT',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Resultado do processo',
          description: '',
          date: 'Terça, 06/04/2021',
        },
        {
          status: 'TRANSFERED_ORDER_ENDED',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Ordem finalizada',
          description: 'Valor transferido para JESSICA CAROLINN DE LIMA OLIVEIRA',
          date: 'Sexta, 09/04/2021',
        },
      ],
      actions: [
        {
          id: 'COPY_NUMERO_PROCESSO',
          type: 'label-with-icon-and-action',
          label: 'NUmero processo',
          value: '20210001152445',
          icon: {
            id: 'copy-icon',
          },
        },
        {
          id: 'BUTTON_DETAILS',
          type: 'button',
          label: 'Mais detalhes',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
        {
          id: 'BUTTON_DOWNLOAD',
          type: 'button',
          label: 'Baixar ordem judicial',
          value: '20210001152445',
          icon: {
            id: '',
          },
        },
      ],
    },
  ],
}
