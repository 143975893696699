import React, { ReactNode } from 'react'

import { ACTION_ID, ACTION_TYPE } from '@/common/constants'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

import { Icon } from '../icon'
import { IconButton } from '../icon-button'
import { Tooltip } from './styles'
/*

import { Icon } from '../icon'
import { ChipStatus } from '../chip-status'
*/
const sections: Record<ACTION_TYPE, (props: Action) => ReactNode> = {
  'label-with-icon-and-action': ({ label, value, action, showTooltip }: Action) => (
    <div className="w-full flex flex-row justify-between items-center">
      <div className="w-full flex flex-col justify-between">
        <Text variant="caption-1" bold colorWeight={500}>
          {label}
        </Text>
        <Text variant="caption-1">{value}</Text>
      </div>
      <IconButton onClick={action}>
        <Icon name="copy" width={24} height={24} />
      </IconButton>
      {showTooltip && (
        <Tooltip active={showTooltip}>
          <span>copiado</span>
        </Tooltip>
      )}
    </div>
  ),
  button: ({ label, id, action, isLoadding }: Action) => (
    <div>
      <Button
        fullWidth
        variant={id === 'BUTTON_DOWNLOAD' ? 'secondary' : 'primary'}
        size="small"
        onClick={action}
        isLoading={id === 'BUTTON_DOWNLOAD' ? isLoadding : false}
      >
        {label}
      </Button>
    </div>
  ),
}
export const ActionSection = ({
  actions,
  openDetail,
  download,
  copy,
  loadding,
  showTooltip,
}: {
  actions: Action[]
  openDetail: (protocolId: string) => void
  download: (protocolId: string) => void
  copy: (protocolId: string) => void
  loadding: boolean
  showTooltip?: boolean
}) => {
  const getAction: Record<ACTION_ID, (protocolId: string) => void> = {
    COPY_NUMERO_PROCESSO: copy,
    BUTTON_DETAILS: openDetail,
    BUTTON_DOWNLOAD: download,
  }

  return (
    <div className=" flex flex-col gap-[10px] pb-[24px]">
      {actions.map((action) =>
        sections[action.type as ACTION_TYPE]({
          ...action,
          showTooltip,
          isLoadding: loadding,
          action: () => getAction[action.id as ACTION_ID](action.value),
        }),
      )}
    </div>
  )
}
/*    <div className="flex flex-row gap-[16px] items-center">
      <Icon name={icon.id} width={24} height={24} color="var(--gray500)" />
      <div className="flex flex-col gap-[8px]">
        <div className="flex flex-col gap-[4px]">
          <Text variant="body-3" bold colorWeight={500}>
            {courtBlockInfo.author}
          </Text>
          <Text variant="body-3">{courtBlockInfo.blockedAmount}</Text>
        </div>
        <ChipStatus label={title} textColor="var(--gray500)" color={color} />
      </div>
    </div> */
