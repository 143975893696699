import { action } from 'cdpro-redux-utils'

import { IWbUserInfo } from '@interco/inter-webview-bridge'

export const Types = {
  Home: {
    SET_HOME: '@Home/SET_HOME',
  },
  User: {
    SET_USER: '@User/SET_USER',
  },
  Detail: {
    SET_PROTOCOL_NUMBER: '@Detail/SET_PROTOCOL_NUMBER',
    SET_DETAIL: '@Detail/SET_DETAIL',
  },
}

export const setHomePage = action<HomePage>(Types.Home.SET_HOME)

export const setDetailPage = action<DetailPage>(Types.Detail.SET_DETAIL)

export const setUser = action<{
  user: IWbUserInfo & { userTestingType?: 'A' | 'B'; cpfCnpj: string }
}>(Types.User.SET_USER)

export const setDetailProtocolNumber = action<{ protocolNumber: string }>(
  Types.Detail.SET_PROTOCOL_NUMBER,
)
