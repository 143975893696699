import React, { useCallback, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

// import { useQuery } from '@tanstack/react-query'
import { WbSessionActions } from '@interco/inter-webview-bridge'
import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { setUser } from '@/contexts/global-state/reducer/actions'
import { HomePageSkeleton } from '@/components/page-skeletons'
import { BaseContainer } from '@/components/base-container'
// import * as ENDPOINTS from '@/common/constants/endpoints'
import { changeTheme, Theme } from '@interco/inter-ui'

const Main = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [{ user }, dispatch] = useGlobalState()
  const onHeaderBackPress = useCallback(() => {
    if ([RoutesNames.ROOT].includes(location.pathname)) {
      BridgeService.interWbNavigate.requestGoBack()
      return
    }

    navigate(-1)
  }, [location, navigate])

  const getAppInfo = useCallback(async () => {
    if (user) return
    const responseAppInfo = await BridgeService.getAppInfo()

    changeTheme(responseAppInfo.application === 'PF' ? Theme.PF : Theme.PJ)

    const response = await BridgeService.getUserInfo()
    if (responseAppInfo.application === 'PJ') {
      const currentBankAccount = response.bankAccountList?.filter(
        (backAccount) => backAccount.current,
      )[0]
      dispatch(
        setUser({
          user: {
            ...response,
            cpf: response.cpf,
            cpfCnpj: currentBankAccount?.cpfCnpj || '',
          },
        }),
      )
    } else {
      dispatch(
        setUser({
          user: {
            ...response,
            cpf: response.cpf,
            cpfCnpj: response.cpf,
          },
        }),
      )
    }
  }, [dispatch, user])

  useEffect(() => {
    BridgeService.iWb.addWebviewEventListener(WbSessionActions.GO_BACK, onHeaderBackPress)
  }, [onHeaderBackPress])

  const onMount = useCallback(() => {
    const callStack = async () => {
      await getAppInfo()
    }
    // Timeout used because MSW not initialized
    BridgeService.inApp ? callStack() : setTimeout(callStack, 1_000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    onMount()
  }, [onMount])
  /*
  const { refetch } = useQuery({
    queryKey: [ENDPOINTS.HOME_PAGE],
    queryFn: async () => {
      if (user?.cpf !== undefined) {
        const resp = await ApiService.Home.getHomePage(user.cpf)

        if (resp === undefined) {
          return null
        }
        const { response } = resp
        dispatch(setHomePage(response))
        navigate(RoutesNames.HOME, { replace: true })
        return response
      }
      return null
    },
    enabled: true,
    retry: false,
    retryDelay: 1000,
  }) */
  useEffect(() => {
    if (user === undefined) return
    // refetch()

    navigate(RoutesNames.HOME, { replace: true })
  }, [navigate, user])
  return (
    <BaseContainer headerTitle="Bloqueio Judicial" animationType="fade">
      <HomePageSkeleton />
    </BaseContainer>
  )
}

export default Main
