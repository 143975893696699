import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 48px);
  background-color: var(--neutral-theme);
  overflow-x: hidden;
`
export const ContainerRightLeft = styled(motion.div).attrs({
  className: 'main__container',
  initial: { x: '100%', opacity: 1 },
  animate: { x: '0%', opacity: 1 },
  exit: { x: '100%', opacity: 1 },
  transition: { duration: 0.4 },
})`
  margin-top: 56px;
  min-height: 100%;
  max-height: calc(100vh - 56px);
  min-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 24px 24px;
`
export const ContainerLeftRight = styled(motion.div).attrs({
  className: 'main__container',
  initial: { x: '-100%', opacity: 1 },
  animate: { x: '0%', opacity: 1 },
  exit: { x: '-100%', opacity: 1 },
  transition: { duration: 0.4 },
})`
  margin-top: 56px;
  min-height: 100%;
  max-height: calc(100vh - 56px);
  min-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 24px 24px;
`
export const ContainerWithFade = styled(motion.div).attrs({
  className: 'main__container',
  initial: { y: '0%', opacity: 0 },
  animate: { y: '0%', opacity: 1 },
  exit: { y: '0%', opacity: 0 },
  transition: { duration: 0.4 },
})`
  margin-top: 56px;
  min-height: 100%;
  max-height: calc(100vh - 56px);
  min-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 24px 24px;
`
export const ContainerBottonTop = styled(motion.div).attrs({
  className: 'main__container',
  initial: { y: '0%', opacity: 0 },
  animate: { y: '0%', opacity: 1 },
  exit: { y: '0%', opacity: 0 },
  transition: { duration: 0.4 },
})`
  min-height: 100%;
  min-width: 100%;
`
