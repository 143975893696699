import React from 'react'

import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import routes from './routes'

const AppRoutes = () => {
  const location = useLocation()
  return (
    <AnimatePresence initial mode="wait">
      <Routes location={location} key={location.pathname}>
        {routes.map((route) => (
          <Route {...route} key={route.path} />
        ))}
      </Routes>
    </AnimatePresence>
  )
}

export default AppRoutes
