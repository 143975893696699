export const Detail: DetailPage = {
  header: 'Bloqueio Judicial',
  icon: 'money-check',
  amount: 'R$ 50,00',
  name: 'CICERO DOS SANTOS',
  status: {
    type: 'UNBLOCKED_PARTIAL',
    color: 'black',
    title: 'Desbloqueado parcialmente',
  },
  sections: [
    {
      title: 'Sobre o bloqueio',
      items: [
        {
          title: 'Protocolo',
          value: '20210000811326',
        },
        {
          title: 'Seq. Bloq.',
          value: '00007',
        },
        {
          title: 'Número do Processo',
          value: '0000053-17.2015.5.19.0061',
        },
        {
          title: 'Data da ordem',
          value: '09/03/2021',
        },
        {
          title: 'Data do bloqueio',
          value: '10/03/2021',
        },
        {
          title: 'Data da finalização',
          value: '12/03/2021',
        },
        {
          title: 'Autor',
          value: 'CICERO DOS SANTOS',
        },
        {
          title: 'Vara/Juízo',
          value: '2  VARA DO TRABALHO DE ARAPIRACA',
        },
      ],
    },
    {
      title: 'Sobre o seu saldo',
      items: [
        {
          title: 'Valor desbloqueado',
          value: 'R$ 50,00',
        },
      ],
    },
  ],
  buttons: [
    {
      title: 'Fechar',
    },
    {
      title: 'Download',
    },
  ],
}
