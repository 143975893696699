import styled from 'styled-components'

export const IconButton = styled.button<{ hoverColor?: string }>`
  transition: all 180ms ease-in-out 0s;
  position: relative;
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor || 'var(--gray100)'};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  z-index: 1;
  background-color: transparent;
`
