import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const ChipContainer = styled.div<{ color: string }>`
  padding: 2px 4px;
  border-radius: 4px;
  height: 16px;
  background: var(${({ color }) => color});
  width: fit-content;
`

export const ChipText = styled(Text).attrs({
  variant: 'caption-2',
  bold: true,
})<{ textColor: string }>`
  display: block;
  color: var(${({ textColor }) => textColor}) !important;
`
