import { rest, setupWorker } from 'msw'

import { DETAIL_PAGE, HOME_PAGE } from '@/common/constants/endpoints'

import { Home } from './responses/home'
import { Detail } from './responses/detail'
// Documentação do MSW disponível em:
// https://mswjs.io/docs/getting-started/mocks/rest-api

// Ao invés de escrever mocks hardcoded, experimente utilizar a biblioteca
// de geração de dados fakes FakerJS (https://fakerjs.dev/)

const DEFAULT_DELAY = 1500
const SUCCESS_STATUS = 200
// const ERROR_STATUS = 403
export const handlers = [
  rest.get(`/${HOME_PAGE}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: Home }),
    ),
  ),
  rest.get(`/${DETAIL_PAGE}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: Detail }),
    ),
  ),
]

export const worker = () => setupWorker(...handlers)
