import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const a = 'a'

export const getCourtBlockPdfUrl = async ({
  cpfCnpj,
  protocolNumber,
}: {
  cpfCnpj: string
  protocolNumber: string
}) => {
  const response = await api<CourtBlockPdfUrl>(`${ENDPOINTS.DOWNLOAD}`, {
    method: 'GET',
    headers: {
      'x-inter-cpf-cnpj': cpfCnpj,
      'x-inter-protocol-number': protocolNumber,
      'x-inter-protocol-position': '0',
    },
  })
  if (!response) {
    return undefined
  }
  return response
}
