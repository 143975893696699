import styled, { css } from 'styled-components'

export const Tooltip = styled.div<{ active: boolean }>`
  ${({ active }) => css`
    position: relative;
    opacity: 1;
    span {
      width: 85px;
      background-color: var(--gray500);
      color: var(--neutral-theme);
      text-align: center;
      border-radius: var(--radiiMd);
      padding: 5px;
      position: absolute;
      bottom: 0;
      left: 0px;
      margin-left: -128px;
      top: -14px;
      height: 30px;
      -webkit-transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }

    transition: opacity 0.3s;
    ${active &&
    css`
      opacity: 1;
    `}
  `}
`
