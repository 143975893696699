import React from 'react'

import { Home } from '@/screens/home'
import Main from '@/main'
import { ErrorPage } from '@/screens/error'
import { Detail } from '@/screens/detail'

export const RoutesNames = {
  ROOT: '/',
  HOME: '/home',
  ERROR: '/error-page',
  DETAIL: '/detail',
}
export default [
  { path: RoutesNames.ROOT, element: <Main /> },
  { path: RoutesNames.HOME, element: <Home /> },
  { path: RoutesNames.DETAIL, element: <Detail /> },
  { path: RoutesNames.ERROR, element: <ErrorPage /> },
]
