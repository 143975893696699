import React, { useCallback, useEffect } from 'react'

import { HashRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom/client'

import './styles.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { enableDarkMode, initTheme, Theme } from '@interco/inter-ui'
import { inApp } from '@/services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'

import AppRoutes from './routes'
import { BaseContainerRoot } from './components/base-container'
import { GlobalStateProvider } from './contexts/global-state'
import { IS_DEVELOPMENT } from './common/constants'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (IS_DEVELOPMENT) {
  const { worker } = require('@/mocks/handlers')
  worker().start()
}

const App = () => {
  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()

    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
    }
  }, [])

  useEffect(() => {
    initTheme(Theme.PF)
    if (inApp) getAppInfo()
  }, [getAppInfo])

  return (
    <React.StrictMode>
      <GlobalStateProvider>
        <QueryClientProvider client={queryClient}>
          <BaseContainerRoot>
            <Router basename="/">
              <AppRoutes />
            </Router>
          </BaseContainerRoot>
        </QueryClientProvider>
      </GlobalStateProvider>
    </React.StrictMode>
  )
}
root.render(<App />)
