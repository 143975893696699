import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import * as S from './styles'

interface IExpandedCard extends PropsWithChildren {
  header: ReactNode
  icon?: ReactElement
  open: boolean
  onExpand: () => void
}
export const ExpandedCard = ({ open, header, children, icon, onExpand }: IExpandedCard) => {
  const [expanded, setExpanded] = useState(open)

  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  const headerIcon =
    icon &&
    React.Children.map(icon, (child) =>
      React.cloneElement(child, {
        style: {
          transition: 'transform 300ms ease-in-out 0s',
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
        },
      }),
    )
  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setHeight(ref?.current?.clientHeight)
    }
  }, [expanded])
  return (
    <S.Container>
      <S.Header
        onClick={() => {
          if (expanded === false) {
            onExpand()
          }
          setExpanded(!expanded)
        }}
        className="px-[16px]"
      >
        {header}

        {icon && headerIcon}
      </S.Header>
      <S.ExpandedPanel style={{ maxHeight: expanded ? height : '0px' }}>
        <div ref={ref}>
          <div className="border-[1px] border-dashed border-[var(--gray200)] my-[16px]" />
          <div className="px-[16px]">{children}</div>
        </div>
      </S.ExpandedPanel>
    </S.Container>
  )
}
