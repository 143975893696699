import { createReducer } from 'cdpro-redux-utils'

import { RootState } from './types'
import { setDetailPage, setDetailProtocolNumber, setHomePage, setUser } from './actions'

export const INITIAL_STATE: RootState = {}

const { reducer, addCase } = createReducer<RootState>(INITIAL_STATE)

addCase(setHomePage, (draft, action) => {
  draft.homePage = action.payload
})

addCase(setUser, (draft, action) => {
  const { user } = action.payload
  draft.user = user
})

addCase(setDetailPage, (draft, action) => {
  draft.detailPage = action.payload
})

addCase(setDetailProtocolNumber, (draft, action) => {
  const { protocolNumber } = action.payload
  draft.detailProtocolNumber = protocolNumber
})

export default reducer
