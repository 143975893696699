import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { Balance } from '@/components/balance'
import { ExpandedCard } from '@/components/expanded-card'
import { HeaderCard } from '@/components/header-card'
import { Icon } from '@/components/icon'
import { TimeLine } from '@/components/time-line'
import { BaseContainer } from '@/components/base-container'
import * as ENDPOINTS from '@/common/constants/endpoints'
import { ApiService, BridgeService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import {
  setDetailPage,
  setDetailProtocolNumber,
  setHomePage,
} from '@/contexts/global-state/reducer/actions'
import { RoutesNames } from '@/routes/routes'
import { HomePageSkeleton } from '@/components/page-skeletons'
import { ActionSection } from '@/components/action-section'
import { formatToBRL } from '@interco/lib-util'
import { copyTextToClipboard, inApp } from '@/services/bridge'
import { showTooltip } from '@/utils/showTooltip'

export const Home = () => {
  const [{ homePage, user }, dispatch] = useGlobalState()

  const [downloadOrderId, setDownloadOrderId] = useState<string>()
  const [displayTooltipId, setDisplayTooltipId] = useState<number>()
  const navigate = useNavigate()

  const openDetail = (protocolId: string, item: CourtBlock) => {
    dispatch(setDetailProtocolNumber({ protocolNumber: protocolId }))

    dispatch(
      setDetailPage({
        header: '',
        icon: item.status.icon?.id || '',
        amount: formatToBRL(Number(item.status.courtBlockInfo?.blockedAmount)),
        name: item.status.courtBlockInfo?.author || '',
        status: item.status,
        sections: [],
        buttons: [],
      }),
    )
    navigate(RoutesNames.DETAIL, { replace: false })
  }
  const download = async (protocolId: string) => {
    setDownloadOrderId(protocolId)
    if (inApp) {
      const reps = await ApiService.Download.getCourtBlockPdfUrl({
        cpfCnpj: user?.cpfCnpj || '',
        protocolNumber: protocolId,
      })
      const response = reps?.response
      if (response)
        BridgeService.iWb
          .execute<unknown, unknown>({
            action: 'downloadFile',
            metadata: { fileUrl: response.url, fileExtension: 'pdf', fileName: 'Ordem judicial' },
          })
          .finally(() => {
            setDownloadOrderId(undefined)
          })
    }
  }
  const copy = (protocolId: string, key: number) => {
    showTooltip(key, setDisplayTooltipId)
    copyTextToClipboard(protocolId)
  }

  useQuery({
    queryKey: [ENDPOINTS.HOME_PAGE],
    queryFn: async () => {
      try {
        const resp = await ApiService.Home.getHomePage(user?.cpfCnpj || '')

        if (resp === undefined) return null

        const { response } = resp
        dispatch(setHomePage(response))

        return response
      } catch (e) {
        navigate(RoutesNames.ERROR, { replace: true })
        return null
      }
    },
  })

  const goBack = () => {
    BridgeService.interWbNavigate.requestGoBack()
  }
  if (homePage === undefined)
    return (
      <BaseContainer
        headerTitle="Bloqueio Judicial"
        onClickArrowLeft={goBack}
        animationType="left-right"
      >
        <HomePageSkeleton />
      </BaseContainer>
    )
  return (
    <BaseContainer
      headerTitle={homePage.header}
      onClickArrowLeft={goBack}
      animationType="left-right"
    >
      <Balance label={homePage.blockedValue.title} value={homePage.blockedValue.value} />
      <div className="flex flex-col gap-[16px] mt-[16px]">
        {homePage.blocks.map((block, index) => (
          <ExpandedCard
            header={<HeaderCard {...block.status} />}
            open={false}
            onExpand={() => null}
            icon={<Icon name="chevrondown" width={24} height={24} color="var(--primary500)" />}
          >
            <TimeLine timeline={block.timeline} />

            <ActionSection
              actions={block.actions.map((item) =>
                item.id === 'COPY_NUMERO_PROCESSO' ? { ...item } : item,
              )}
              showTooltip={displayTooltipId === index}
              openDetail={(protocolId) => openDetail(protocolId, block)}
              download={download}
              copy={(value: string) => copy(value, index)}
              loadding={
                downloadOrderId ===
                block.actions.filter((item) => item.id === 'BUTTON_DOWNLOAD')[0].value
              }
            />
          </ExpandedCard>
        ))}
      </div>
    </BaseContainer>
  )
}
