import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'

export const Balance = ({ label, value }: { label: string; value: string }) => (
  <div className="border-[1px] border-solid border-[var(--gray200)] rounded-[8px] flex justify-between px-[12px] py-[4px] mt-[24px]">
    <Text variant="caption-1">{label}</Text>
    <Text variant="caption-1" bold colorWeight={500}>
      {value}
    </Text>
  </div>
)
