import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './styles'
import { ExpandedCard } from '../expanded-card'

const cardSkeleton = () => (
  <div className="flex flex-row w-full items-center justify-center">
    <Skeleton animation="pulse" width="24px" height="24px" variant="circle" />
    <div className="flex flex-col w-full p-[8px]">
      <Skeleton animation="pulse" width="100%" height="8px" className="mb-[8px]" />
      <Skeleton animation="pulse" width="80%" height="8px" />
    </div>
  </div>
)
const labelAndValueSkeleton = () => (
  <div className="flex flex-row w-full items-center justify-between mb-[8px]">
    <Skeleton animation="pulse" width="100px" height="8px" />
    <Skeleton animation="pulse" width="100px" height="8px" />
  </div>
)
export const TitleSkeleton = () => <Skeleton animation="pulse" width="100%" height="25px" />
export const CardSkeleton = () => (
  <Skeleton
    animation="pulse"
    width="100%"
    height="94px"
    style={{ marginBottom: 8, marginTop: 16 }}
  />
)

export const ButtonSkeleton = () => (
  <Skeleton
    animation="pulse"
    width="100%"
    height="48px"
    role="main"
    data-testid="button-skeleton"
  />
)
export const HomePageSkeleton = () => (
  <S.Container role="main" data-testid="loadding">
    {[...Array(8)].map(() => (
      <div className="mb-[16px]">
        <ExpandedCard header={cardSkeleton()} open={false} onExpand={() => null} />
      </div>
    ))}
  </S.Container>
)

export const DetailPageSkeleton = () => (
  <S.Container role="main" data-testid="loadding" className="h-fit">
    <Skeleton animation="pulse" width="150px" height="30px" className="mb-[16px]" />
    {[...Array(8)].map(() => labelAndValueSkeleton())}
    <Skeleton animation="pulse" width="150px" height="30px" className="mb-[16px]" />
    {[...Array(8)].map(() => labelAndValueSkeleton())}
  </S.Container>
)
